import { render, staticRenderFns } from "./about.vue?vue&type=template&id=470ea0de&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./about.vue?vue&type=script&lang=js&"
export * from "./about.vue?vue&type=script&lang=js&"
import style0 from "./about.vue?vue&type=style&index=0&id=470ea0de&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "470ea0de",
  null
  
)

export default component.exports