<template xmlns="http://www.w3.org/1999/html">
  <div>
    <div class=" main cf">
      <h2 class="h2">关于我们</h2>
      <div class="main_left">
        <a href="#">小程序设计</a>
        <a href="#">数据大屏</a>
        <a href="#">管理系统</a>
        <a href="#">定制开发</a>
        <a href="#">电商系统</a>
        <a href="#">智慧社区</a>
      </div>
      <div class="main_right">
        <h4>公司简介</h4>
        <p>
          成都弈尘云网络科技有限公司是一家专业的软件开发公司，主要提供小程序、网站、管理系统、大屏显示和定制开发等一系列服务。我们拥有一支高素质、专业化的研发团队，以创新、高效、质量为核心价值观，为客户提供最优质的解决方案。

          <br><br>  我们专注于软件开发多年，积累了丰富的经验和技术。我们的小程序、网站、管理系统等产品，不仅具备高度的可用性和用户体验，还采用了先进的技术和设计理念，保证了系统的高效性和安全性。我们的大屏显示系统采用了最先进的互动技术，为客户提供了全新的视觉体验。

          <br><br> 我们还提供定制开发服务，为客户提供个性化、专业化的解决方案。我们的专业团队将根据客户需求，量身定制开发方案，以满足客户的需求和期望。

          <br><br>  我们的宗旨是：以客户需求为导向，为客户提供优质的产品和服务。我们的目标是：成为客户信赖和选择的首选合作伙伴。
          <br><br>
        </p>
        <div class="main_right_2">
          <h4>企业理念:</h4>
          <p>
            成都弈尘云网络科技有限公司秉承创新、高效、质量的核心价值观，不断追求卓越和完美，致力于为客户提供最优质的产品和服务。我们的公司理念包括以下几个方面：

            <br><br>1、客户导向：我们的首要目标是满足客户的需求和期望。我们深入了解客户需求，以客户的利益为重，为客户提供高品质、高性价比的产品和服务。

            <br><br>2、创新发展：我们持续不断地推陈出新，倡导创新发展。我们鼓励员工积极创新，提倡尝试新想法和新技术，以不断提高产品和服务质量。

            <br><br>3、团队合作：我们重视团队合作，鼓励员工积极沟通、合作，共同协作完成项目。我们坚信，只有团结协作，才能创造出最佳的工作效果。

            <br><br>4、诚信服务：我们重视诚信服务，坚持诚信做人、诚信做事，为客户提供真实、可靠的产品和服务。

            <br><br>5、持续改进：我们不断改进，追求卓越。我们持续跟进市场需求和技术发展，不断改进产品和服务，以满足客户需求和期望。

            我们相信，这些理念将使我们成为一个更优秀的公司，为客户提供更优质的产品和服务，为员工提供更好的发展和学习机会，同时也为社会做出更大的贡献。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.main{
  padding: 0 5%;
  box-sizing: border-box;
}
.main_left {
  float: left;
  width: 261px;
  margin-right: 28px;
}

.main_left a {
  font-size: 12px;
  width: 261px;
  height: 48px;
  display: inline-block;
  text-align: center;
  line-height: 48px;
  background: #f2f2f2;
  margin-bottom: 2px;
}
.main_left img {
  width: 261px;
  margin: 20px 0 30px 0;
}
.main_right h4 {
  padding-bottom: 10px;
}
.main_right p {
  font-size: 14px;
  color: #818388;
  line-height: 2em;
}
.main_right_2 {
  margin-top: 30px;
}
 @media screen and (max-width:500px) {
  .main_left , .main_left a{
        width: 140px !important;
  }
 }
</style>